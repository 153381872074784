import { BooleanField, Datagrid, DateTimeInput, EditButton, List, SearchInput, ShowButton, TextField } from 'react-admin';
import CustomDateField from '../components/CustomDateField';

const UserList = () => {
    const postFilters = [
        <SearchInput key='Search' source='q' alwaysOn />,
        <DateTimeInput key='createdAt_gte' source='createdAt_gte' label='Created after' />,
        <DateTimeInput key='createdAt_lte' source='createdAt_lte' label='Created before' />,
    ];

    return (
        <List exporter={false} filters={postFilters} filter={{ deletedAt: null }} sort={{ field: 'createdAt', order: 'DESC' }}>
            <Datagrid>
                <TextField source='firstname' />
                <TextField source='lastname' />
                <TextField source='email' />
                <BooleanField source='disabled' />
                <CustomDateField source='createdAt' />
                <ShowButton />
                <EditButton />
            </Datagrid>
        </List>
    );
};

export default UserList;
