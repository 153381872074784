import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { useEffect, useState } from 'react';
import { useDataProvider } from 'react-admin';

const DashboardItem = ({ title, count }) => {
    const size = '150px';

    const style = {
        minWidth: size,
        maxWidth: size,
        minHeight: size,
        maxHeight: size,
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    };

    return (
        <Paper variant='outlined' sx={style}>
            <Stack direction='column'>
                <div style={{ fontSize: '250%' }}>{count}</div>
                <div style={{ fontSize: '90%'}}>{title}</div>
            </Stack>
        </Paper>
    );
}

DashboardItem.propTypes = {
    title: PropTypes.string,
    count: PropTypes.number
}

const DashboardMetrics = () => {
    const dataProvider = useDataProvider();
    const [metrics, setMetrics] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        dataProvider.getDashboardMetrics()
            .then((data) => {
                setMetrics(data.metrics);
                setLoading(false);
            })
    }, [
        dataProvider
    ]);

    const cardContent = loading
        ? (
            <>
                <Skeleton variant='rectangular' width={150} height={150}></Skeleton>
                <Skeleton variant='rectangular' width={150} height={150}></Skeleton>
                <Skeleton variant='rectangular' width={150} height={150}></Skeleton>
                <Skeleton variant='rectangular' width={150} height={150}></Skeleton>
            </>
        )
        : (
            <>
                <DashboardItem title='Connections' count={metrics.connectionCount} />
                <DashboardItem title='Total students' count={metrics.totalStudents} />
                <DashboardItem title='Total trainers' count={metrics.totalTrainers} />
                <DashboardItem title='Trainers with license request' count={metrics.trainersWithLicenseRequest} />
                <DashboardItem title='Trainers subscribed' count={metrics.trainersWithActiveLicense} />
                <DashboardItem title='Trainers completed' count={metrics.trainerProfilesCompleted} />
                <DashboardItem title='Trainers validated' count={metrics.trainerProfilesValidated} />
                <DashboardItem title='Trainings available' count={metrics.trainingsAvailable} />
                <DashboardItem title='Visios completed' count={metrics.visiosCompleted} />
            </>
        );

    return (
        <Stack direction='row' justifyContent='center' flexWrap='wrap' gap={2}>
            {cardContent}
        </Stack>
    );
};

export default DashboardMetrics;
