import { Admin, Resource } from 'react-admin';
import authProvider from './authProvider';
import dataProvider from './dataProvider';

import './App.css';

import LoginPage from './pages/LoginPage';
import Dashboard from './pages/Dashboard';
import StudentList from './student/StudentList';
import StudentEdit from './student/StudentEdit';
import TrainerList from './trainers/TrainerList';
import TrainerShow from './trainers/TrainerShow';
import StudentShow from './student/StudentShow';
import TrainerEdit from './trainers/TrainerEdit';
import TrainerProfileEdit from './trainers/TrainerProfileEdit';
import UserList from './users/UserList';
import TrainerAdminEdit from './trainers/TrainerAdminEdit';
import UserShow from './users/UserShow';
import UserEdit from './users/UserEdit';
import UserCreate from './users/UserCreate';
import AnnouncementList from './announcements/AnnouncementList';
import AnnouncementShow from './announcements/AnnouncementShow';
import AnnouncementEdit from './announcements/AnnouncementEdit';
import AnnouncementCreate from './announcements/AnnouncementCreate';
import ConnectionList from './connections/ConnectionList';
import ConnectionEdit from './connections/ConnectionEdit';
import TrainingList from './trainings/TrainingList';
import TrainingShow from './trainings/TrainingShow';
import TrainingEdit from './trainings/TrainingEdit';
import LicenseCostList from './license-price/LicenseCostList';
import LicenseRequestList from './license-request/LicenseRequestList';
import LicenseRequestEdit from './license-request/LicenseRequestEdit';

const App = () => <Admin
    authProvider={authProvider}
    dataProvider={dataProvider}
    loginPage={LoginPage}
    dashboard={Dashboard}
    requireAuth
    disableTelemetry
>
    <Resource name='students' list={StudentList} edit={StudentEdit} show={StudentShow} />
    <Resource name='trainers' list={TrainerList} edit={TrainerEdit} show={TrainerShow} />
    <Resource name='trainer-profiles' edit={TrainerProfileEdit} />
    <Resource name='trainer-admins' edit={TrainerAdminEdit} />
    <Resource name='trainings' list={TrainingList} edit={TrainingEdit} show={TrainingShow} />
    <Resource name='announcements' list={AnnouncementList} show={AnnouncementShow} edit={AnnouncementEdit} create={AnnouncementCreate} />
    <Resource name='backoffice/users' options={{ label: 'Users' }} list={UserList} show={UserShow} edit={UserEdit} create={UserCreate} />
    <Resource name='connections' list={ConnectionList} edit={ConnectionEdit} />
    <Resource name='license-cost' options={{ label: 'Subscription price' }} list={LicenseCostList} />
    <Resource name='license-requests' options={{ label: 'Requests' }} list={LicenseRequestList} edit={LicenseRequestEdit} />
</Admin>;

export default App;
