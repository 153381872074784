import Fab from '@mui/material/Fab';
import { useState } from 'react';
import { Confirm, useDataProvider, useRecordContext, useRefresh } from 'react-admin';

const TrainerValidationButton = () => {
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const trainer = useRecordContext();

    const [showConfirmValidate, setShowConfirmValidate] = useState(false);

    if (trainer == null || trainer.status === 'created') {
        return <div></div>;
    }

    if (trainer.status === 'submitted') {
        const validateProfile = () => {
            dataProvider.validateTrainer({ id: trainer.id }).then((result) => {
                if (result.success) {
                    refresh();
                    setShowConfirmValidate(false);
                } else {
                    alert('An error occured');
                }
            });
        };

        return (
            <>
                <Fab variant='extended' color='primary' sx={{ position: 'fixed', bottom: 20, right: 50 }} onClick={() => setShowConfirmValidate(true)}>
                    Valider...
                </Fab>
                <Confirm
                    isOpen={showConfirmValidate}
                    title='Valider profil'
                    content='Etes-vous certaine de vouloir valider ce profil ?'
                    onConfirm={() => validateProfile()}
                    onClose={() => setShowConfirmValidate(false)}
                />
            </>
        );
    }

    const unvalidateProfile = () => {
        dataProvider.unvalidateTrainer({ id: trainer.id }).then((result) => {
            if (result.success) {
                refresh();
                setShowConfirmValidate(false);
            } else {
                alert('An error occured');
            }
        });
    };

    return (
        <>
            <Fab variant='extended' color='default' sx={{ position: 'fixed', bottom: 20, right: 50 }} onClick={() => setShowConfirmValidate(true)}>
                Dévalider...
            </Fab>
            <Confirm
                isOpen={showConfirmValidate}
                title='Dévalider profil'
                content='Etes-vous certaine de vouloir dévalider ce profil ?'
                onConfirm={() => unvalidateProfile()}
                onClose={() => setShowConfirmValidate(false)}
            />
        </>
    );
};

export default TrainerValidationButton;
