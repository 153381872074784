export const LicenseRequestStatus = Object.freeze({
    values: {
        NOT_TREATED: 'not_treated',
        INVOICE_SENT: 'invoice_sent',
        PAYED: 'payed'
    },
    translate(value) {
        if (value == null) {
            return value;
        }

        switch (value) {
        case this.values.NOT_TREATED:
            return 'Non traité';
        case this.values.INVOICE_SENT:
            return 'Facture envoyée';
        case this.values.PAYED:
            return 'Payé';
        default:
            throw new RangeError(`Invalid value '${value}'`);
        }
    }
});
